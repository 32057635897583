import { render, staticRenderFns } from "./register.vue?vue&type=template&id=0b7bf0af"
import script from "./register.vue?vue&type=script&lang=js"
export * from "./register.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Theme3MobilePagesInterRegisterPage: require('/var/app/components/Theme3/Mobile/Pages/InterRegisterPage.vue').default,Theme3MobilePagesRegisterPage: require('/var/app/components/Theme3/Mobile/Pages/RegisterPage.vue').default,Theme3DesktopPagesInterRegisterPage: require('/var/app/components/Theme3/Desktop/Pages/InterRegisterPage.vue').default,Theme3DesktopPagesRegisterPage: require('/var/app/components/Theme3/Desktop/Pages/RegisterPage.vue').default})
